nav.primary-navigation {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 2vw;
}
.nav-table {
    display: inline-block;
}
.nav-logo {
    width: 10vw;
    padding-right: 5vw;
}
nav.primary-navigation ul li {
    list-style: none;
    margin: 0 auto;
    border-left: 2px solid #3ca0e7;
    display: inline-block;
    padding: 0 3vw;
    position: relative;
    text-decoration: none;
    text-align: center;
}
nav.primary-navigation li .nav-link {
    color: black;
}
nav.primary-navigation li .nav-link:hover {
    color: #3ca0e7;
}
nav.primary-navigation li:hover {
    cursor: pointer;
}
nav.primary-navigation ul li ul {
    font-size: 1.5vw;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    padding-left: 0;
    left: 0;
    display: none;
    background: white;
}
nav.primary-navigation ul li:hover > ul, nav.primary-navigation ul li ul:hover {
    visibility: visible;
    opacity: 1;
    display: block;
    min-width: 250px;
    text-align: left;
    padding-top: 20px;
    box-shadow: 0px 3px 5px -1px #ccc;
}
nav.primary-navigation ul li ul li {
    clear: both;
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
    border-style: none;
}
nav.primary-navigation ul li ul li .nav-link:hover {
    padding-left: 10px;
    border-left: 2px solid #3ca0e7;
    transition: all 0.3s ease;
}
.nav-link {
    text-decoration: none;
}
.nav-link:hover {
    color: #3ca0e7;
}
ul li ul li .nav-link {
    transition: all 0.5s ease;
}
