.case-portfolio-container img {
    width: 25%;
    display: inline-block;
    margin: 2vw 4.15%;
    height: max(20vw, 20vh);
    cursor: pointer;
    object-fit: cover;
}

.case-portfolio-clicked {
    position: fixed;
    height: 85%;
    cursor: pointer;
    top: 50%;
    left: 50%;
    max-width: 95%;
    transform: translate(-50%, -50%);
    border: 5px white solid;
}

.case-portfolio-clicked-background {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.7);;
    z-index: 100000;
}

.kitchen-main-image {
    background-image: url("kitchen-more3.jpg") !important;
}

.basement-main-image {
    background-image: url("basement-3.jpg") !important;
}

.bathroom-main-image {
    background-image: url("bathroom-3.jpg") !important;
}

.house-main-image {
    background-image: url("house-4.jpg") !important;
}

@media screen and (max-width: 880px) {
    .case-portfolio-container img {
        width: 80%;
        margin: max(3vw, 3vh) 10%;
        height: max(33vw, 33vh);
    }

    .case-portfolio-clicked {
        height: unset!important;
        max-width: unset!important;
        max-height: 90%;
        width: 85%;
    }
}