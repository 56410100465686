.homepage-header {
    background-image: url('kitchen-1336160_1920.jpg');
    height: 70vh;
    width: 100%;
    margin-top: 3vh;
    margin-bottom: 2vh;
    background-repeat: no-repeat;
    background-size: 100vw auto;
}

.not-underlined {
    text-decoration:none!important;
}

@media screen and (max-width: 650px) {
    nav.primary-navigation {
        padding-top: 2vh;
    }
}

@media screen and (max-width: 1220px) {
    .homepage-header {
        background-size: auto 70vh;
        margin-top: 2vh;
    }
}
