
/*See more button*/

.text {
    text-align: center;
    padding-bottom: 1%;
}

#container {
    text-align: center;
}

button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
}
button.learn-more {
    width: 12rem;
    height: auto;
}
button.learn-more .circle {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin: 0;
    width: 3rem;
    height: 3rem;
    background: #2d56b2;
    border-radius: 1.625rem;
}
button.learn-more .circle .icon {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fff;
}
button.learn-more .circle .icon.arrow-see-more {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    left: 0.625rem;
    width: 1.125rem;
    height: 0.125rem;
    background: none;
}
button.learn-more .circle .icon.arrow-see-more::before {
    position: absolute;
    content: '';
    top: -0.25rem;
    right: 0.0625rem;
    width: 0.625rem;
    height: 0.625rem;
    border-top: 0.125rem solid #fff;
    border-right: 0.125rem solid #fff;
    transform: rotate(45deg);
}

button.learn-more .button-text {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.75rem 0;
    margin: 0 0 0 1.85rem;
    color: #282936;
    font-weight: 700;
    line-height: 1.6;
    text-align: center;
    text-transform: uppercase;
}
button:hover .circle {
    width: 100%;
}
button:hover .circle .icon.arrow-see-more {
    background: #fff;
    transform: translate(1rem, 0);
}
button:hover .button-text {
    color: #fff;
}
@supports (display: grid) {
    #container {
        grid-area: main;
        align-self: center;
        justify-self: center;
    }
}

/* explore button */

.button-explore {
    z-index: 100;
    display: block;
    width: 320px;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 0;
    overflow: hidden;
    position: relative;
    transform: translatez(0);
    text-decoration: none;
    box-sizing: border-box;
    font-size: 24px;
    font-weight: normal;
    box-shadow: 0 9px 18px rgba(0,0,0,0.2);
    text-align: center;
    border-radius: 50px;
    padding: 26px;
    color: white;
    /*background: purple;
    */ background: rgb(45, 86, 178);
    transition: all 0.2s ease-out 0s;

}
.button-explore:active{
    color: white;
    text-shadow: 
                -1px 2px 6px rgb(255, 255, 255), 
                -1px -1px 0 rgba(0,0,0,.87);
}
    
.button-explore:hover{
    color: white;
    text-shadow: 
                -1px 2px 6px rgb(255, 255, 255), 
                -1px -1px 0 rgba(0,0,0,.87);
}
.gradient {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    bottom: auto;
    margin: auto;
    z-index: -1;
    background: radial-gradient(90px circle at top center, rgb(7, 105, 177) 30%, rgba(255,255,255,0));
    transition: all 0s ease-out 0s;
    transform: translatex(-140px);
    animation: 18s linear 0s infinite move;
}

@keyframes move {
    0% {
        transform: translatex(-140px);
    }
    25% {
        transform: translatex(140px);
        opacity: 0.3;
    }
    50% {
        transform: translatex(140px);
        opacity: 1;
        background: radial-gradient(90px circle at bottom center, rgb(7, 105, 177) 30%, rgba(255,255,255,0));
    }
    75% {
        transform: translatex(-140px);
        opacity: 0.3;
    }
    100% {
        opacity: 1;
        transform: translatex(-140px);
        background: radial-gradient(90px circle at top center, rgb(7, 105, 177) 30%, rgba(255,255,255,0));
    }
}