.projects-header {
    text-align: center;
    background-image: url('basement-6.jpg');
    height: 60vh;
    width: 100%;
    margin-bottom: 2vh;
    background-repeat: no-repeat;
    background-size: 100vw auto;
}

.projects-header h1 {
    font-size: 2.5em;
    padding-top: 32vh;
    color: #ffffff;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000
}

@media screen and (max-width: 770px) {
    .projects-header h1 {
        font-size: 2em;
    }
}

@media screen and (max-width: 810px) {
    .projects {
        background-size: auto 60vh;
    }
}