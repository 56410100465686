footer {
    background: #000000!important;
    height: 30vh;
    padding-left: 3vw;
    padding-right: 3vw;
    color: #ffffff;
    z-index: 0;
 }

.footer-link {
    text-decoration:none!important;
    color: #ffffff;
    display: block;
    padding-bottom: 1vh;
}

.footer-link:hover {
    color: firebrick;
}

.footer-title {
    padding-top: 3vh;
    color: #636363;
    margin-bottom: 5vh;
}

.footer-2 {
    padding-left: 3vw;
}

.footer-1 {
    line-height: 1.6;
}

.footer-3 {
    line-height: 1.6;
}

.footer-bottom {
    border-top: 1px groove #b2b2b2;
    padding-top: 1vh;
    background: #000000!important;
    text-align: center;
    color: #b2b2b2;
    padding-bottom: 20px;
    font-size: 12px;
}

@media screen and (max-width: 960px) {
    footer {
        height: 32vh;
    }
}

@media screen and (max-width: 800px) {
    footer {
        height: 35vh;
    }

    .footer-1 {
        display: none;
    }

    .footer-2 {
        width: 45vw;
    }

    .footer-3 {
        width: 45vw;
    }
}

@media screen and (max-width: 480px) {
    footer {
        height: 60vh;
    }
    .footer-2 {
        width: 90vw;
    }

    .footer-3 {
        width: 90vw;
    }
}