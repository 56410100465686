.card-btn {
    width: 15vw;
    height: 5vh;
}

@media screen and (max-width: 1100px) {
    .MuiCardContent-root h2 {
        font-size: 1.2em!important;
    }
    .MuiCardContent-root p {
        font-size: 0.9em!important;
    }
}

@media screen and (max-width: 880px) {
    .card-btn {
        width: 20vw;
        font-size: 0.7em!important;
    }
}

@media screen and (max-width: 630px) {
    .card-btn {
        font-size: 0.6em!important;
        width: 30vw;
    }
}