.contact-header {
    text-align: center;
    background-image: url('contact-us.jpg');
    height: 60vh;
    width: 100%;
    margin-bottom: 2vh;
    background-repeat: no-repeat;
    background-size: 100vw auto;
}

.contact-header h1 {
    font-size: 2.5em;
    padding-top: 32vh;
    color: #ffffff;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000
}

.left-contact {
    width: 40vw;
    padding-left: 14vw;
}

.right-contact {
    width: 28vw;
    margin: 0 10vw 0 2vw;
    background-color:black;
    text-align: center;
    color: white;
    padding: 0 4vw;
}

@media screen and (max-width: 900px) {
    .left-contact {
        width: 80vw;
        padding: 0 10vw;
        display: block;
    }


    .submit-btn {
        margin-right: 60vw;
        width: 25vw;
    }

    .right-contact {
        display: block;
        width: 74vw;
        padding: 5vh 10vw;
        margin: 5vh 2vw;
    }
}

@media screen and (max-width: 1150px) {
    .contact-header {
        background-size: auto 60vh;
    }
}

@media screen and (max-width: 400px) {
    .form-field__label {
        font-size: 1rem;
    }
}
