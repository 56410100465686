.kitchen-title-img {
    padding-left: 2.3vw;
    padding-right: 2.3vw;
    width: 20vw;
}

.kitchen-projects-div {
    text-align: center;
    margin: 10vh 0 10vh 0;
}

.kitchen-projects-div h1 {
    padding-bottom: 5vh;
}

.kitchen-projects {
    padding-left: 1.3vw;
    padding-right: 1.3vw;
    width: 30vw;
}

.house-header {
    text-align: center;
    background-image: url('living-room-2583032_1920.jpg');
    height: 60vh;
    width: 100%;
    margin-bottom: 2vh;
    background-repeat: no-repeat;
    background-size: 100vw auto;
}

.house-header h1 {
    font-size: 2.5em;
    padding-top: 32vh;
    color: #ffffff;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000
}

.kitchen-text {
    margin: 10vh 0 10vh 0;
    text-align: center;
    box-sizing: border-box;
    line-height: 1.45;
}

.kitchen-text h1 {
    font-weight: normal;
    color: #333;
}

.kitchen-text p {
    font-weight: lighter;
    color: #757575;
    padding: 0 25vw 0 25vw;
    box-sizing: border-box;
}

.house-contact .left {
    background-image: url('home-office-336377_1920.jpg');
    height: 60vh;
    width: 50vw;
    margin-bottom: 2vh;
    background-repeat: no-repeat;
    background-size: 50vw auto;
    display: inline-block;
}

.house-contact .right {
    display: inline-block;
    background: #000000;
    height: 60vh;
    width: 48vw;
    text-align: center;
    position: relative;
    margin: -3px 0 2vh -1.5vw;
}
.house-contact {
    height: 61vh;
}

.house-contact .right h1{
    color: #ffffff;
    padding: 18vh 2vw 0 2vw;
}

.house-contact .right h2{
    color: #757575;
    padding: 0 5vw 0 5vw;
}

.btn-contact {
    background: #ffffff!important;
    color: #000000!important;
    font-weight: bold!important;
    margin-top: 3vw!important;
    border: unset!important;
    font-size: 1em;
}

.btn-contact:hover {
    background: red!important;
    color: #ffffff!important;
}

@media screen and (max-width: 770px) {
    .house-contact .left {
        display: unset;
    }

    .house-contact .right {
        display: block;
        width: auto;
        top: 0;
    }

    .house-header h1 {
        font-size: 2em;
    }

    .kitchen-projects {
        padding-left: 2vw;
        padding-right: 2vw;
        width: 45vw;
    }

    .no-mobile {
        display: none;
    }

    .kitchen-projects-div h1 {
        padding-bottom: 3vh;
    }
}

@media screen and (max-width: 1650px) {
    .house-contact .left {
        background-size: auto 60vh;
    }
}

@media screen and (max-width: 1220px) {
    .kitchen-text p {
        padding: 0 20vw 0 20vw;
    }
    .house-contact .right h1{
        color: #ffffff;
        padding: 17vh 2vw 0 2vw;
    }
}

@media screen and (max-width: 930px) {
    .kitchen-title-img {
        padding-left: 2vw;
        padding-right: 2vw;
        width: 45vw;
        margin-bottom: 0.5vh;
    }
    .kitchen-text p {
        padding: 0 15vw 0 15vw;
    }

    .house-contact .right h1{
        color: #ffffff;
        padding: 15vh 2vw 0 2vw;
    }

}
@media screen and (max-width: 960px) {
    .house-header {
        background-size: auto 60vh;
    }
}

@media screen and (max-width: 750px) {
    .kitchen-title-img {
        padding-left: 1.8vw;
        padding-right: 1.8vw;
    }
    .kitchen-projects {
        padding-left: 1.8vw;
        padding-right: 1.8vw;
    }
    .kitchen-text p {
        padding: 0 10vw 0 10vw;
    }
}

@media screen and (max-width: 540px) {
    .kitchen-title-img {
        padding-left: 1.6vw;
        padding-right: 1.6vw;
    }
    .kitchen-projects {
        padding-left: 1.6vw;
        padding-right: 1.6vw;
    }
    .kitchen-text p {
        padding: 0 5vw 0 5vw;
    }
}

@media screen and (max-width: 420px) {
    .kitchen-title-img {
        padding-left: 1vw;
        padding-right: 1vw;
    }
    .kitchen-projects {
        padding-left: 1vw;
        padding-right: 1vw;
    }
    .kitchen-text p {
        padding: 0 3vw 0 3vw;
    }
}